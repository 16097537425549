export const APIMICK = "https://localhost:32770/api";
{
  /* Mick local host */
}
export const APIRJB = "https://localhost:44375/api";
{
  /* Richard local host **/
}
   export const API = "https://vesta.mysasure.com/api"; /* { live api url *}*/
  // export const API = "https://dev-vesta.mysasure.com/api";
  // export const API = "https://staging-vesta.mysasure.com/api";
 // export const API = "https://localhost:44375/api";
{
  /* live api url **/
}
export const APIKEY = "b54a9101-d7b2-4e27-81b7-920da0f0b1ca";
